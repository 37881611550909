
import {throwError as observableThrowError,  of ,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map, concatAll, } from 'rxjs/operators';
import { } from 'google-maps';
import { ApiService } from './api.service';
const apiKey = environment.googleApiKey;
const apiUrlGeocoding = `https://maps.googleapis.com/maps/api/geocode/json?key=${apiKey}`;
// const apiUrlAutocomplete = `https://maps.googleapis.com/maps/api/place/autocomplete/json?key=${apiKey}`;
const apiUrlProcxyAutocomplete = `/api/place/autocomplete`;

export interface IGeoLocation {
  lat: number;
  lng: number;
}

interface IGoogleApiResponse {
  status: 'ERROR' | 'INVALID_REQUEST' | 'OK' | 'OVER_QUERY_LIMIT' | 'REQUEST_DENIED' | 'UNKNOWN_ERROR' | 'ZERO_RESULTS';
    // status: google.maps.GeocoderStatus; // enum ?!
}

interface IGeocoderResponse extends IGoogleApiResponse  {
  results: any[]; // google.maps.GeocoderResult
}

interface IAutocompleteResponse extends IGoogleApiResponse {
  predictions: google.maps.places.AutocompletePrediction[];
}

@Injectable()
export class GeocodingService {
  private cityGeoLocation: IGeoLocation = {
    lat: 34.075376,
    lng: -84.29409,
  };
  constructor(private api: ApiService, private http: HttpClient) { }
  getGeocoding(address: string) {
    const qAddress = this._qstring(address);

    return this.http.get<IGeocoderResponse>(apiUrlGeocoding,
      {
      params: new HttpParams().set('address', qAddress),
    }
    )
      .pipe(
        map((response) => {
          if (response.status !== 'OK') {
          throw Error(`Geocoding API Error Status: ${response.status}`);
          }
          const location = response.results[0].geometry.location;
          const loc: IGeoLocation = { lat: location.lat, lng: location.lng };
          return loc;
        }),
        catchError((err) => {
          console.log(err);
          return of(undefined as IGeoLocation);
        })
      );
  }
  // NOT IN USE
  getPlaces(address: string) {
    const administrativeArea = 'GA';
    const locality = 'alpharetta';
    const country = 'US';
    const postal_code = '';
    const qAddress = this._qstring(address);
    const components = `locality:${locality}|administrative_area:${administrativeArea}|country:${country}`;

    return this.http.get<IGeocoderResponse>(apiUrlGeocoding,
      {
      params: new HttpParams()
      .set('address', qAddress)
      .set('components', components),
    }
    )
      .pipe(
        map((response) => {
          if (response.status !== 'OK') {
            observableThrowError(`Geocoding API Error Status: ${response.status}`);
          }
          const places = response.results;
          return places;
        }),
        catchError((err) => {
          console.log(err);
          return of(undefined as IGeoLocation);
        })
      );
  }

  autocompleteAddress(address: string, sessionToken: number) {
    const qAddress = this._qstring(address);
    // const input = `${qAddress}, Alpharetta, GA`;
    const input = qAddress;
    // const offset = input.indexOf(',').toString();
    return this.api.get<IAutocompleteResponse>(apiUrlProcxyAutocomplete, { params:[
      {paramName: 'types', paramValue: 'geocode'},
      {paramName: 'components', paramValue: 'country:US'},
      {paramName: 'input', paramValue: input},
      {paramName: 'sessionToken', paramValue: `${sessionToken}`},
      {paramName: 'location', paramValue: `${this.cityGeoLocation.lat},${this.cityGeoLocation.lng}`},
      {paramName: 'radius', paramValue: '2500'},
    ]}
    //   {
    //   params: new HttpParams()
    //   .set('types', 'address')
    //   .set('components', 'country:US')
    //   // .set('offset', offset)
    //   .set('input', input)
    //   .set('sessionToken', `${sessionToken}`)
    //   .set('location', `${this.cityGeoLocation.lat},${this.cityGeoLocation.lng}`)
    //   .set('radius', '2500')
    // }
    )
    .pipe(
      map((response) => {
        if (response.status !== 'OK') {
          throw Error(`Autocomplete API Error Status: ${response.status}`);
        }
        // const addresses = response.predictions.map(a => a.structured_formatting.main_text);
        const addresses = response.predictions.map(a => a.description);
        return addresses;
      }),
      catchError((err) => {
        console.log(err);
        return of(undefined as string[]);
      })
    );


  }

  getAddress(lat: number, lng: number) {

    return this.http.get<IGeocoderResponse>(apiUrlGeocoding, {
      params: new HttpParams().set('latlng', `${lat}, ${lng}`),
    })
      .pipe(
        map((response) => {
          if (response.status !== 'OK') {
          throw Error(`Geocoding API Error Status: ${response.status}`);
          }
          const address: string = response.results[0].formatted_address;
          return address;
        }),
        catchError((err) => {
          console.log(err);
          return of('');
        })
      );
  }

  private _qstring(str: string) {
    return str
      .trim()
      .replace(/\s\s+/g, ' ')
      .replace(/\s/g, '+');
  }
}
