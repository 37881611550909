import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { UserblockService } from './userblock.service';
import { AuthService } from '../../../auth/auth.service';
import { ProfileService } from '../../../routes/profile/shared/profile.service';
import { map, flatMap, tap, switchMap } from 'rxjs/operators';
import { TenantService } from '../../../core/tenant/tenant.service';
@Component({
  selector: 'app-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss'],
  providers: [ProfileService],
})
export class UserblockComponent implements OnInit {
  // user: any;
  // profile: any;
  readonly profile$ = this.auth.userProfile$;
  readonly tenantLogo$ = this.tenantService
  .currentTenant$.pipe(
    switchMap(() => this.tenantProfile.read()),
    map(tp => tp.logoImgUrl),
  );

  constructor(
    public userblockService: UserblockService,
    public auth: AuthService,
    private tenantProfile: ProfileService,
    private tenantService: TenantService
  ) {}

  ngOnInit() {
    // this.auth.userProfile$.subscribe(profile => {
    //    if (profile) {
    //        this.profile = profile;
    //    }
    // });
  }
  userBlockIsVisible() {
    return this.userblockService.getVisibility();
  }
}
