import { Component, OnInit, Input, Output, EventEmitter, Self, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TimeZoneService } from '../../../providers/time-zone.service';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
  providers: [TimeZoneService]
})
export class DateTimeComponent implements ControlValueAccessor, OnInit {

  constructor(@Self() @Optional() public controlDir: NgControl, public tz: TimeZoneService) {
    this.controlDir.valueAccessor = this;
   }

  @Input() startTime: Date;
  @Output() startTimeChange = new EventEmitter<Date>();
  @Input() endTime: Date;
  @Output() endTimeChange = new EventEmitter<Date>();
  @Input() value:  any | any[];
  @Output() valueChange = new EventEmitter<Date | Date[]>();
  // @Input() tenantTZ: string;
  disabled: boolean;
  timeZoneAbbr = this.tz.abbrTenant;
  startAt = this.tz.nowMinutesZero();
  // startAt: any;
  private onChange = (_: any) => { };
  private onTouched = () => { };
  writeValue(obj: any): void {
    // throw new Error("Method not implemented.");
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
    }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _onOpened() {
    if (this.value) {
    }
  }
  _onClosed() {
    if (!this.value || !Array.isArray(this.value)) { return; }

  }

  _onChange($event) {
    if (!this.value || !Array.isArray(this.value) || !this.value[0] || !this.value[1]) { return; }
    console.log($event);
    this.startTime = moment(this.value[0]).toDate();
    this.endTime = moment(this.value[1]).toDate();
    // this.startTime =  moment.tz(this.value[0], this.tenantTZ).format();
    // this.endTime =  moment.tz(this.value[1], this.tenantTZ).format();
    this.startTimeChange.emit(this.startTime);
    this.endTimeChange.emit(this.endTime);

  }

  ngOnInit() {
    // moment.tz.setDefault(this.tenantTZ);
    // this.startAt = this.nowMinutesZero();
    
    if (this.startTime && this.endTime) {
      this.value = [moment(this.startTime), moment(this.endTime)];
    }
  }

  // nowMinutesZero() {
  //   const date = moment.tz(new Date(), this.tenantTZ).minutes(0).toDate();
  //   return date;
  // }

  // timeZoneAbbr(date?: Date | string) {
  //   if (!date) {
  //     return moment.tz(new Date(), this.tenantTZ).zoneAbbr();
  //   }
  //   return moment.tz(date, this.tenantTZ).zoneAbbr();
  // }

}
