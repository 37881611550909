import { Component, OnInit, ViewChild } from '@angular/core';
const screenfull = require('screenfull');
const browser = require('jquery.browser');
declare var $: any;

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';

import { AuthService } from '../../auth/auth.service';
import { TenantService } from '../../core/tenant/tenant.service';
import { Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
// import * as _ from 'lodash';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: []
})
export class HeaderComponent implements OnInit {
  tenants$ = this.tenant.userTenants$.pipe(
    map(tenants => {
      this.isMultiTenant = tenants.length >= 2;
      tenants.sort(( a, b ) => a.viewName.localeCompare(b.viewName));
      return tenants;
    })
  );
  isMultiTenant: boolean;
  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout

  isNavSearchVisible: boolean;
  @ViewChild('fsbutton') fsbutton; // the fullscreen button

  constructor(
    public menu: MenuService,
    public userblockService: UserblockService,
    public settings: SettingsService,
    private tenant: TenantService,
    private router: Router,
    public auth: AuthService
  ) {
    // show only a few items on demo
    this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
  }

  ngOnInit() {
    this.isNavSearchVisible = false;
    if (browser.msie) {
      // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }
  }

  toggleUserBlock(event) {
    event.preventDefault();
    this.userblockService.toggleVisibility();
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    // console.log(stat);
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar() {
    this.settings.layout.offsidebarOpen = !this.settings.layout.offsidebarOpen;
  }

  toggleCollapsedSideabar() {
    this.settings.layout.isCollapsed = !this.settings.layout.isCollapsed;
  }

  isCollapsedText() {
    return this.settings.layout.isCollapsedText;
  }

  toggleFullScreen(event) {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
    // Switch icon indicator
    const el = $(this.fsbutton.nativeElement);
    if (screenfull.isFullscreen) {
      el.children('em')
        .removeClass('fa-expand')
        .addClass('fa-compress');
    } else {
      el.children('em')
        .removeClass('fa-compress')
        .addClass('fa-expand');
    }
  }

  get currentTenant() {
    if (this.tenant.tenantName) {
      return this.tenant.tenantName;
    }
    return '';
  }

  tenantSelected(value) {
    this.router.navigate(['/']);
    this.tenant.setTenant(value);
  }
}
