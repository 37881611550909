<div class="item user-block" *ngIf="userBlockIsVisible()">
    <ng-container *ngIf="profile$ | async as profile else loading">
        <!-- User picture-->
        <div class="user-block-picture">
            <div class="user-block-status">
                <img *ngIf="tenantLogo$ | async as tenantLogo" class="img-thumbnail img-circle" [src]="tenantLogo" alt="Avatar" />
            </div>
        </div>
        <!-- Name and Job-->
        <div class="user-block-info">
            <span class="user-block-name">{{ 'sidebar.WELCOME' | translate }} {{ profile?.name }}</span>
            <span class="user-block-role">{{ profile?.email }}</span>
        </div>
    </ng-container>
    <ng-template #loading>
        <div class="loading">
            <mat-spinner></mat-spinner>
        </div>
    </ng-template>
</div>