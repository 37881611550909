// ITsMyTownAPP

const Home = {
  text: 'Home',
  link: '/home',
  icon: 'house',
  scopes: ['default'],
};

const Profile = {
  text: 'Profile',
  link: '/profile',
  // icon: 'icon-settings',
  icon: 'manage_accounts',
  scopes: ['read:profile', 'create:profile', 'update:profile'],
};

const MenuBuilder = {
  text: 'TABS Navigator',
  link: '/menu-builder',
  icon: 'menu_open',
  scopes: ['read:menus', 'create:menus', 'update:menus'],
};

const Jurisdiction = {
  text: 'Jurisdiction',
  link: '/jurisdiction',
  icon: 'edit_location_alt_black_24dp',
  scopes: [
    'read:jurisdiction',
    'create:jurisdiction',
    'update:jurisdiction',
    'delete:jurisdiction',
  ],
};

// CITY STAFF
const Polls = {
  text: 'Polls',
  link: '/polls',
  icon: 'poll_black_24dp',
  scopes: ['read:polls', 'create:polls', 'update:polls', 'delete:polls'],
};

const StaffCommBoard = {
  text: 'Staff CommBoard',
  link: '/commboard',
  icon: 'icon-notebook',
  scopes: [
    'read:staff-commboard',
    'create:staff-commboard',
    'update:staff-commboard',
    'delete:staff-commboard',
  ],
};

const BoardsCommissions = {
  text: 'Boards Commissions',
  link: '/boards-commissions',
  icon: 'space_dashboard',
  scopes: [
    'read:boards-commissions',
    'create:boards-commissions',
    'update:boards-commissions',
    'delete:boards-commissions',
  ],
};

const Readiness = {
  text: 'Resources',
  link: '/readiness',
  // icon: 'icon-people',
  icon: 'checklist',
  scopes: [
    'read:readiness',
    'create:readiness',
    'update:readiness',
    'delete:readiness',
  ],
};

const CouncilMeetings = {
  // text: 'Council Meetings',
  text: 'Clerk',
  link: '/council-meetings',
  // icon: 'icon-people',
  icon: 'thumb_up',
  scopes: [
    'read:council-meetings',
    'create:council-meetings',
    'update:council-meetings',
    'delete:council-meetings',
  ],
};

const Reports = {
  // text: 'Issue Reports',
  text: 'Reports',
  link: '/reports/dashboard',
  // icon: 'icon-wrench',
  icon: 'remove_red_eye',
  scopes: ['read:issues', 'create:issues', 'update:issues', 'delete:issues'],
  submenu: [
    {
      text: 'Dashboard',
      link: '/reports/dashboard',
    },
    {
      text: 'Performance',
      link: '/reports/performance',
    },
  ],
};

const Jobs = {
  text: 'Careers',
  link: '/jobs',
  // icon: 'icon-briefcase',
  icon: 'business_center',
  scopes: ['read:jobs', 'create:jobs', 'update:jobs', 'delete:jobs'],
};

const Parks = {
  // text: 'Parks, Public Places',
  text: 'Places',
  link: '/parks',
  // icon: 'icon-location-pin',
  icon: 'nature_people',
  scopes: ['read:places', 'create:places', 'update:places', 'delete:places'],
};

const Contacts = {
  text: 'Contacts',
  link: '/city-contacts',
  // icon: 'icon-people',
  icon: 'contact_mail',
  scopes: [
    'read:contacts',
    'create:contacts',
    'update:contacts',
    'delete:contacts',
  ],
  // submenu: [{
  //     text: 'All Contacts',
  //     link: '/contacts'
  // },
  // {
  //     text: 'Categories',
  //     link: '/contacts/categories'
  // }]
};

const CityNews = {
  text: 'Newsflash',
  link: '/city-news',
  // icon: 'icon-book-open',
  icon: 'campaign',
  scopes: ['read:news', 'create:news', 'update:news', 'delete:news'],
};

const RealTimeAlerts = {
  // text: 'Real-Time Alerts',
  text: 'Alerts',
  link: '/city-realtime-alerts',
  // icon: 'fa fa-bullhorn',
  icon: 'notification_important',
  scopes: ['read:alerts', 'create:alerts', 'update:alerts', 'delete:alerts'],
};

const Events = {
  text: 'Events',
  link: '/city-events',
  // icon: 'icon-calendar',
  icon: 'calendar_today',
  scopes: ['read:events', 'create:events', 'update:events', 'delete:events'],
};

const NewToCity = {
  text: 'Places for Newcomers',
  link: '/new-to-city',
  icon: 'icon-info',
  scopes: [
    'read:stations',
    'create:stations',
    'update:stations',
    'delete:stations',
  ],
};

const Stations = {
  text: 'Stations',
  link: '/stations',
  // icon: 'icon-info',
  icon: 'shield',
  scopes: [
    'read:stations',
    'create:stations',
    'update:stations',
    'delete:stations',
  ],
};

const CityParking = {
  text: 'Parking',
  link: '/city-parking',
  // icon: 'fa fa-car',
  icon: 'local_parking',
  scopes: [
    'read:parkings',
    'create:parkings',
    'update:parkings',
    'delete:parkings',
  ],
};

// Local Business

const LocalBusiness = {
  text: 'Businesses',
  link: '/local-businesses',
  icon: 'icon-briefcase',
  scopes: [
    'read:businesses',
    'create:businesses',
    'update:businesses',
    'delete:businesses',
  ],
};

const BusinessesStandOut = {
  text: 'Stand Out',
  link: '/businesses-out-stand',
  icon: 'icon-star',
  scopes: ['read:bos', 'create:bos', 'update:bos', 'delete:bos'],
};

const OnlineServices = {
  text: 'Services',
  link: '/online-services',
  // icon: 'fa fa-question-circle',
  icon: 'real_estate_agent',
  scopes: [
    'read:online-services',
    'create:online-services',
    'update:online-services',
    'delete:online-services',
  ],
};

const businessCategories = {
  text: 'Business Categories',
  link: '/business-categories',
  icon: 'fa fa-tty',
  scopes: [
    'read:business-categories',
    'create:business-categories',
    'update:business-categories',
    'delete:business-categories',
  ],
};

const goBig = {
  text: 'GoBig',
  link: '/go-big',
  icon: 'icon-rocket',
  scopes: ['read:gobig', 'create:gobig', 'update:gobig', 'delete:gobig'],
};

// CONVENTION VISITORS BUREAU

const ContentUpdate = {
  text: 'Content Update',
  link: '/content-update',
  icon: 'icon-note',
  scopes: ['read:cvb', 'create:cvb', 'update:cvb', 'delete:cvb'],
};

// ITsimple FEEDS

const GeneralNews = {
  text: 'Local General News',
  link: '/general-news',
  icon: 'fa fa-newspaper-o',
  scopes: [
    'read:local-news',
    'create:local-news',
    'update:local-news',
    'delete:local-news',
  ],
};

// Manage

const Users = {
  // text: 'TA & All Users',
  text: 'Trusted Admins',
  link: '/users',
  // icon: 'icon-user',
  icon: 'supervised_user_circle',
  scopes: ['read:users', 'create:users', 'update:users', 'delete:users'],
};

// Blogs

const Authors = {
  // text: 'Leadership Profiles',
  text: 'Leaders Bio',
  link: '/authors',
  // icon: 'icon-people',
  icon: 'groups',
  scopes: [
    'read:authors',
    'create:authors',
    'update:authors',
    'delete:authors',
  ],
};

const Posts = {
  // text: 'Posts from Leadership',
  text: 'Leaders Blog',
  link: '/posts',
  // icon: 'icon-note',
  icon: 'forum',
  scopes: ['read:posts', 'create:posts', 'update:posts', 'delete:posts'],
};

const Departments = {
  text: 'Departments',
  link: '/departments',
  // icon: 'fa fa-building-o',
  icon: 'business',
  scopes: [
    'read:departments',
    'create:departments',
    'update:departments',
    'delete:departments',
  ],
};

const CustomPages = {
  text: 'Pages',
  link: '/pages',
  icon: 'web',
  scopes: ['read:pages', 'create:pages', 'update:pages', 'delete:pages'],
};

const Tenants = {
  text: 'Tenants',
  link: '/tenants',
  // icon: 'icon-flag',
  icon: 'switch_account',
  scopes: [
    'read:tenants',
    'create:tenants',
    'update:tenants',
    'delete:tenants',
  ],
};

const CityTour = {
  text: 'Tours',
  link: '/city-tours',
  icon: 'icon-map',
  scopes: ['read:tours', 'create:tours', 'update:tours', 'delete:tours'],
};

const Members = {
  text: 'Users Access',
  link: '/members',
  icon: 'person_add_alt_1',
  scopes: [
    'read:members',
    'create:members',
    'update:members',
    'delete:members',
  ],
};

const Activity = {
  text: 'Activity',
  link: '/activity',
  // icon: 'icon-list',
  icon: 'list',
  scopes: ['read:users', 'create:users', 'update:users', 'delete:users'],
};

const MediaLibrary = {
  text: 'Files',
  link: '/file-manager',
  // icon: 'icon-picture',
  icon: 'file_present',
  scopes: ['read:users', 'create:users', 'update:users', 'delete:users'],
};

const Highlights = {
  text: 'ICONS/BOXES',
  link: '/highlights',
  icon: 'folder_special',
  // TODO: update scopes
  scopes: ['read:pages', 'create:pages', 'update:pages', 'delete:pages'],
};

// Heading

const headingMain = {
  text: 'ITsMyTown APP',
  heading: true,
  scopes: ['default'],
};

const headingCity = {
  text: 'LOCAL GOV. STAFF',
  heading: true,
  scopes: [
    'issues',
    'alerts',
    'places',
    'contacts',
    'services',
    'news',
    'events',
    'polls',
  ],
};

// const headingManage = {
//   text: 'TRUSTED ADMIN',
//   heading: true,
//   scopes: ['members']
// };

const headingConventionVisitors = {
  text: 'CVB',
  heading: true,
  scopes: ['cvb'],
};

const headingBusinessOwner = {
  text: 'BUSINESS OWNER',
  heading: true,
  scopes: ['businesses', 'bos', 'gobig'],
};

const headingITsimpleFeeds = {
  text: 'ITsimple FEEDS',
  heading: true,
  scopes: ['local-news'],
};

const headingPowerUser = {
  text: 'POWER USER',
  heading: true,
  scopes: ['users'],
};

const headingBlogs = {
  text: 'BLOGS',
  heading: true,
  scopes: ['authors', 'posts'],
};

const headingWebsite = {
  text: 'WEBSITE',
  heading: true,
  scopes: ['pages'],
};

export const menu = [
  // headingMain,
  Home,
  Profile,
  Members,
  Jurisdiction,
  MediaLibrary,
  headingWebsite,
  CustomPages,
  MenuBuilder,
  Highlights,
  headingCity,
  Readiness,
  StaffCommBoard,
  CouncilMeetings,
  BoardsCommissions,
  Reports,
  Jobs,
  Polls,
  RealTimeAlerts,
  Parks,
  Contacts,
  CityNews,
  Events,
  NewToCity,
  Stations,
  Departments,
  CityParking,
  OnlineServices,
  headingBlogs,
  Authors,
  Posts,
  headingConventionVisitors,
  ContentUpdate,
  CityTour,
  // headingManage,
  // Members,
  headingBusinessOwner,
  businessCategories,
  goBig,
  LocalBusiness,
  BusinessesStandOut,
  headingITsimpleFeeds,
  GeneralNews,
  headingPowerUser,
  Users,
  Tenants,
  Activity,
];
