import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import {
  catchError,
  first,
  switchMap,
} from 'rxjs/operators';
// import { _throw } from 'rxjs/Observable/throw';
import { TenantService } from '../core/tenant/tenant.service';
import { Router } from '@angular/router';
const API_URL = `${environment.apiUrl}`;

interface IRequestOptions {
  body?: any;
  params?: { paramName: string; paramValue: string }[];
  withTenant?: boolean;
  baseUrl?: string;
}

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private router: Router,
    public tenant: TenantService
  ) {}

  private get _authorization(): string {
    return `Bearer ${this.auth.accessToken}`;
  }

  // TODO: Change the handling with the tenant
  private _apiTenantPath(): string {
    if (this.tenant.tenantName) {
      return `city/${this.tenant.tenantName}`;
    } else {
      this.router.navigate(['/home']);
      throw new Error('Tenant Error');
    }
  }

  private _authRequest<T>(
    method:
      | 'POST'
      | 'PUT'
      | 'PATCH'
      | 'DELETE'
      | 'GET'
      | 'HEAD'
      | 'JSONP'
      | 'OPTIONS',
    path: string,
    options: IRequestOptions = {}
  ) {
    return this.auth.hasAuthenticated$.pipe(
      // skipWhile((auth) => !auth),
      first(),
      switchMap(() => {
        options.baseUrl = (options && options.baseUrl) || API_URL;
        const url =
          options && options.withTenant
            ? `${options.baseUrl}/${this._apiTenantPath()}/${path}`
            : `${options.baseUrl}/${path}`;
        let httpParams: HttpParams | undefined;
        if (options && options.params) {
          httpParams = new HttpParams();
          options.params.forEach(p => {
            httpParams = httpParams.set(p.paramName, p.paramValue);
          });
        }
        return this.http.request<T>(method, url, {
          responseType: 'json',
          headers: new HttpHeaders().set('Authorization', this._authorization),
          body: options && options.body ? options.body : undefined,
          params: options && options.params ? httpParams : undefined
        });
      })
    );
  }

  public get<T>(path: string, options?: IRequestOptions) {
    // return this.tenant.currentTenant$.pipe(
    //   switchMap((tenant) => {
    //     return this._authRequest<T>('GET', path, options);
    //   }),
    //   // first(),
    //   catchError((error) => this._handleError(error))
    // );
    return this._authRequest<T>('GET', path, options).pipe<T>(
      catchError(error => this._handleError(error))
    );
  }

  public post<T>(path: string, options: IRequestOptions) {
    return this._authRequest<T>('POST', path, options).pipe<T>(
      catchError(error => this._handleError(error))
    );
  }

  public put<T>(path: string, options: IRequestOptions) {
    return this._authRequest<T>('PUT', path, options).pipe<T>(
      catchError(error => this._handleError(error))
    );
  }

  public patch<T>(path: string, options: IRequestOptions) {
    return this._authRequest<T>('PATCH', path, options).pipe<T>(
      catchError(error => this._handleError(error))
    );
  }

  public delete<T>(path: string, options: IRequestOptions) {
    return this._authRequest<T>('DELETE', path, options).pipe<T>(
      catchError(error => this._handleError(error))
    );
  }

  private _handleError(err: HttpErrorResponse | any): Observable<any> {
    const errorMsg = err.message || 'Error: Unable to complete request.';
    if (err.status == 401) {
      this.auth.closeSession();
    }
    if (err.message && err.message.indexOf('No JWT present') > -1) {
      this.auth.login();
    }
    throw Error(errorMsg);
  }
}
