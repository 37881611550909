
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Profile } from './profile.interface';
import { ApiService } from '../../../providers/api.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ProfileService {

  constructor(private api: ApiService) {
  }

  read(): Observable<Profile> {
    return this.api.get<Profile>('hometown', { withTenant: true }).pipe(
      map(response => {
        const data: Profile = response;
        return data;
      }), catchError(err => {
        console.log(err);
        return observableThrowError(err);
      }));
  }

  update(profile: Profile) {
    return this.api.post<Profile>('hometown', { withTenant: true, body: profile }).pipe(
      map(response => {
        const data: Profile = response;
        return data;
      }), catchError(err => {
        console.log(err);
        return observableThrowError(err);
      }));
  }

  readWithTenant(tenant: string): Observable<Profile> {
    return this.api.get<Profile>(`hometown/${tenant}`, { withTenant: true }).pipe(
      map(response => {
        const data: Profile = response;
        return data;
      }), catchError(err => {
        console.log(err);
        return observableThrowError(err);
      }));
  }
  
  updateAddress(profile: Profile, tenant: string) {
    return this.api.post<Profile>(`hometown/${tenant}`, { withTenant: true, body: profile }).pipe(
      map(response => {
        const data: Profile = response;
        return data;
      }), catchError(err => {
        console.log(err);
        return observableThrowError(err);
      }));
  }

}
