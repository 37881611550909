import { Component, OnInit, OnDestroy } from '@angular/core';
declare var $: any;

import { SettingsService } from '../../core/settings/settings.service';
import { ThemesService } from '../../core/themes/themes.service';
import { TranslatorService } from '../../core/translator/translator.service';
import { TenantService } from '../../core/tenant/tenant.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-offsidebar',
    templateUrl: './offsidebar.component.html',
    styleUrls: ['./offsidebar.component.scss']
})
export class OffsidebarComponent implements OnInit, OnDestroy {

    currentTheme: any;
    selectedLanguage: string;
    clickEvent = 'click.offsidebar';
    $doc: any = null;
    tenants$ = this.tenant.tenants$;

    constructor(public settings: SettingsService,
        public themes: ThemesService,
        public translator: TranslatorService,
        private tenant: TenantService,
        private router: Router,
        ) {
        this.currentTheme = themes.getDefaultTheme();
        this.selectedLanguage = this.getLangs()[0].code;
    }

    get currentTenant() {
        if (this.tenant.tenantName) {
            return this.tenant.tenantName;
        }
        return '';
    }
    ngOnInit() {
        this.anyClickClose();
    }

    setTheme() {
        this.themes.setTheme(this.currentTheme);
    }

    getLangs() {
        return this.translator.getAvailableLanguages();
    }

    setLang(value) {
        this.translator.useLanguage(value);
    }

    anyClickClose() {
        this.$doc = $(document).on(this.clickEvent, (e) => {
            if (!$(e.target).parents('.offsidebar').length) {
                this.settings.layout.offsidebarOpen = false;
            }
        });
    }

    tenantSelected(value) {
        this.tenant.setTenant(value);
        this.router.navigate(['/']);
    }

    ngOnDestroy() {
        if (this.$doc) {
            this.$doc.off(this.clickEvent);
        }
    }
}
