<div class="editor">
    <quill-editor [(ngModel)]="htmlContent"
                  [placeholder]="'Enter content'"
                  [theme]="'snow'"
                  [modules]="quillConfig"
                  (onSelectionChanged)="onSelectionChanged($event)"
                  (onBlur)="onBluer($event)"
                  (onContentChanged)="onHTMLContentChanged($event)"
                  ></quill-editor>
</div>
