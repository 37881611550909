<mat-form-field >
  <mat-label for="address">{{ label || 'Address' }}</mat-label>
  <input [(ngModel)]="address" matInput placeholder="{{ placeholder || '' }}" type="text" [value]="address" [matAutocomplete]="auto"
    [disabled]="disabled || onMapSelection" (input)="_onChangeEvent($event.target.value)" (blur)="_onBluer()" name="address" [required] = "required">
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (onSelectionChange)="selectedAddress(option)">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
  <!-- <button (click)="addressGeo(addressInput.value, $event)" mat-button matSuffix mat-icon-button aria-label="Target">
        <mat-icon>my_location</mat-icon>
    </button> -->
  <mat-error *ngIf="controlDir && controlDir.control.touched && !controlDir.control.valid">
    The address is required, please start typing and select from the list that appears.
  </mat-error>
  <!-- <mat-error *ngIf="address && (!latitude || !longitude) && controlDir.control.touched">Click on to set the location and showing on the map</mat-error> -->
</mat-form-field>
<ng-container *ngIf="onMapSelection">
<p>
  * Shows the closest address according to the selected location
</p>
</ng-container>
<!-- <mat-form-field>
    <input type="text" placeholder="Address" aria-label="Address" matInput [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
</mat-form-field> -->
<ng-container *ngIf="showMap">
  <mat-checkbox *ngIf="mapDblClickable" color="primary" [(ngModel)]="onMapSelection">Select location on the map (double clicking)</mat-checkbox>
  <agm-map (mapDblClick)="onMapDbClick($event)" [latitude]="mapGeoLocation.lat" [longitude]="mapGeoLocation.lng"
    [streetViewControl]="false" [zoom]="17" mapTypeId="roadmap" [disableDoubleClickZoom]=true class="gmap">
    <agm-marker [latitude]=latitude [longitude]=longitude *ngIf="latitude && longitude" iconUrl="assets/img/marker/primary.svg"></agm-marker>
  </agm-map>
</ng-container>