export const environment = {
  production: false,
  baseUrl: 'https://dev-portal.itsmytown.co',
  apiUrl: 'https://data-entry-api-dot-its-my-town-dev.appspot.com/admin',
  reportServiceUrl: 'https://311service-dot-its-my-town-dev.appspot.com/admin',
  pollWebsiteUrl: 'https://itsimple-poll-dev.web.app/',
  authConfig: {
    clientID: 'eWYou17w6crdXF2gKcrZqALW3OlFZLGZ',
    domain: 'itsmytown-dev.auth0.com',
    audience: 'https://ITsMyTown',
    callbackURL: 'https://dev-portal.itsmytown.co/callback'
  },
  cloudinaryApiKey: '773541113612443',
  cloudinaryName: 'itsmytown',
  cloudinaryPreset: 'wlfgcrog',
  facebookAppId: '278636052867384',
  facebookAppSecret: 'e29bcbee50b37577be8b4f4381c53331',
  googleApiKey: 'AIzaSyBu3nqYKppRr8eWORscheIfPflbe0k3QOI',
  googleMapApiKey: 'AIzaSyBheMqoIIp1jiBUghtKC4PJfiQVDyanpYo',
};
