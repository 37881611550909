import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ImageUploadV2Service } from '../../../providers/image-upload-v2.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {
  placeholderImgUrl = 'assets/img/dummy.png';
  @Input() croppingAspectRatio = 1.5;
  @Input() tags: string | string[] = 'none';
  @Input() src: string;
  @Output() srcChange = new EventEmitter<string>();
  constructor(private imageUpload: ImageUploadV2Service) { }
  openUploadImageDialog() {
    this.imageUpload.upload(this.tags, this.croppingAspectRatio).subscribe(imgUrl => {
      if (imgUrl) {
        this.src = imgUrl;
        this.srcChange.emit(imgUrl);
      }
    });
  }
  cancelImage() {
    this.imageUpload.delete(this.src).subscribe(res => {
      if (res['result'] === 'ok') {
        this.src = '';
        this.srcChange.emit('');
      } else if (res['result'] === 'not found') {
        console.log('Image not exists');
        this.src = '';
        this.srcChange.emit('');
      }
      else {
        console.log('Failed image delete');
      }
    })
  }
  ngOnInit() {
  }
}
