import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';

interface IMenuItem {
  text: string;
  heading?: boolean;
  link?: string; // internal route links
  elink?: string; // used only for external links
  target?: string; // anchor target="_blank|_self|_parent|_top|framename"
  icon?: string;
  alert?: string;
  submenu?: any[];
  scopes: any[];
}

@Injectable()
export class MenuService {
  menuItems = [];
  private menuItemsSource = new BehaviorSubject<IMenuItem[]>([]);
  readonly menuItems$ = this.menuItemsSource.asObservable();

  constructor(public auth: AuthService) {
  }

  generateMenu(items: IMenuItem[]) {
    return this.auth.userProfile$.pipe(
      map(userProfile => {
        if (!userProfile || !userProfile.permissions) {
            return;
        }
        const userScopes = userProfile.permissions;
        const headerScopes = [];
        userScopes.map(scope => {
          let headerScope;
          headerScope = scope.split(':')[1];
          if (headerScopes.indexOf(headerScope) === -1) {
            headerScopes.push(headerScope);
          }
        });
        return items.map(item => {
          let is_include = false;
          item.scopes.map(scope => {
            if (scope === 'default') {
              is_include = true;
            } else {
              if (item.heading) {
                if (headerScopes.indexOf(scope) > -1) {
                  is_include = true;
                }
              } else {
                if (userScopes.indexOf(scope) > -1) {
                  is_include = true;
                }
              }
            }
          });
          if (is_include) {
            return item;
          }
        });
      }),
      map(menuItems => {
          if (Array.isArray(menuItems)) {
            const menu = menuItems.filter(item => !!item);
            const sortedMenu = this.sortMenu(menu);
            this.menuItemsSource.next(sortedMenu);
            return menu;
          }
      })
    );
  }

  sortMenu(menuItems) {
    let sortMenu = [];
    let tempMenu = [];
    const last_header_index_old = _.findLastIndex(menuItems, [
      'heading',
      true
    ]);
    for (let i = 0; i < menuItems.length; i++) {
      if (menuItems[i].heading) {
        if (tempMenu.length > 0) {
          tempMenu = _.sortBy(tempMenu, 'text');
          sortMenu = sortMenu.concat(tempMenu);
          tempMenu = [];
        }
        sortMenu.push(menuItems[i]);
        if (i === last_header_index_old) {
          let last_menu_array = menuItems.slice(
            i + 1,
            menuItems.length
          );
          last_menu_array = _.sortBy(last_menu_array, 'text');
          sortMenu = sortMenu.concat(last_menu_array);
          break;
        }
      } else {
        const last_header_index = _.findLastIndex(sortMenu, ['heading', true]);
        if (last_header_index > 0) {
          tempMenu.push(menuItems[i]);
        } else {
          sortMenu.push(menuItems[i]);
        }
      }
    }
    menuItems = sortMenu;
    return menuItems;
  }

  // Not in use
  addMenu(items: IMenuItem[]) {
    const userScopes = this.auth.scopes;
    console.log('user scopes:', userScopes);
    if (!userScopes) {
      return;
    }
    const headerScopes = [];
    userScopes.map(scope => {
      let headerScope;
      headerScope = scope.split(':')[1];
      if (headerScopes.indexOf(headerScope) === -1) {
        headerScopes.push(headerScope);
      }
    });

    items.forEach(item => {
      let is_include = false;
      item.scopes.map(scope => {
        if (scope === 'default') {
          is_include = true;
        } else {
          if (item.heading) {
            if (headerScopes.indexOf(scope) > -1) {
              is_include = true;
            }
          } else {
            if (userScopes.indexOf(scope) > -1) {
              is_include = true;
            }
          }
        }
      });
      if (is_include) {
        this.menuItems.push(item);
      }
    });
  }
  getMenu() {
    return this.menuItems;
  }

}
