import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageComponent } from './image/image.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ImageUploadV2Service } from '../../providers/image-upload-v2.service';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ],
  providers: [ImageUploadV2Service],
  declarations: [ImageComponent],
  exports: [ImageComponent],
})
export class ImageUploadModule { }
