<tabset [justified]="true">
    <tab>
        <ng-template tabHeading>
            <em class="icon-equalizer fa-lg"></em>
        </ng-template>
        <h3 class="text-center text-thin">Settings</h3>
        <div class="p">
            <!-- <h4 class="text-muted text-thin">Tenant</h4>
            <mat-form-field>
                <mat-select placeholder="Select Tenant" [value]="currentTenant"
                    (selectionChange)=tenantSelected($event.value)>
                    <mat-option *ngFor="let tenant of tenants$ | async" [value]="tenant.name">
                        {{ tenant.viewName }}
                    </mat-option>
                </mat-select>
            </mat-form-field> -->
        </div>
    </tab>
</tabset>