<div class="wrapper">
  <div class="block-center mt-xl wd-xl">
      <!-- START panel-->
      <div class="panel panel-dark panel-flat">
          <div class="panel-heading text-center">
              <a href="#">
                  <img class="block-center img-rounded" src="assets/img/logo.png" alt="Image" />
              </a>
          </div>
          <div class="panel-body">
              <h1 class="text-center">Welcome</h1>
              <p class="text-center pv">SIGN IN TO CONTINUE.</p>
              <button class="btn btn-block btn-primary mt-lg" (click)="auth.login()">Login</button>
          </div>
          <div class="panel-footer">
                <p>
                    Portal access exclusively for authorized customers. <a href="http://104.197.166.170/index.php/contact-us/">Contact us</a>
                </p>
          </div>
      </div>
      <!-- END panel-->
      <div class="p-lg text-center">
          <span>&copy;</span>
          <span>{{ settings.app.year }}</span>
          <span>-</span>
          <span>{{ settings.app.name }}</span>
          <br/>
          <!-- <span>{{ settings.app.description }}</span> -->
      </div>
  </div>
</div>