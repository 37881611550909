import { Injectable } from '@angular/core';
import swal, { SweetAlertOptions } from 'sweetalert2';

interface AlertDialogTexts {
  action: string;
  text: string;
  confirmText: string;
  cancelText: string;
}

interface ResultDialog {
  success();
  failed(error: string);
}

@Injectable()
export class AlertDialogService {
  constructor() {}

  deleteDialog(itemType: string) {
    return this.sureDialog({
      action: 'Deleted',
      text: 'You won\'t be able to revert this!',
      confirmText: `Your ${itemType} has been deleted.`,
      cancelText: `Your ${itemType} is safe :)`
    });
  }

  sureDialog(alertDialogTexts: AlertDialogTexts) {
    return new Promise<ResultDialog>((resolve, reject) => {
      swal({
        titleText: 'Are you sure?',
        text: alertDialogTexts.text,
        type: 'warning',
        showCancelButton: true,
        buttonsStyling: true,
        reverseButtons: true,
        confirmButtonText: 'Confirm',
      }).then(result => {
        if (result.value) {
          resolve({
            success: () => {
              swal(
                `${alertDialogTexts.action}!`,
                alertDialogTexts.confirmText,
                'success'
              );
            },
            failed: err => {
              swal('Error', err, 'error');
            }
          });
        } else if (result.dismiss === swal.DismissReason.cancel) {
          swal('Cancelled', alertDialogTexts.cancelText, 'error');
          reject('Cancelled');
        }
      });
    });
  }

  confirmDialog(alertDialogTexts: AlertDialogTexts, promise?: Promise<any>) {
    swal({
      titleText: 'Are you sure?',
      text: alertDialogTexts.text,
      type: 'warning',
      showCancelButton: true,
      buttonsStyling: true,
      reverseButtons: true,
      confirmButtonText: 'Confirm',
      preConfirm: () => {
        promise
          .then(() => {
            swal(
              `${alertDialogTexts.action}!`,
              alertDialogTexts.confirmText,
              'success'
            );
          })
          .catch(() => {
            swal.insertQueueStep({
              type: 'error',
              title: 'Some error'
            });
          });
      }
    }).then(result => {
      if (result.value) {
      } else if (result.dismiss === swal.DismissReason.cancel) {
        swal('Cancelled', alertDialogTexts.cancelText, 'error');
      }
    });
  }

  simpleConfirmDialog(alertDialogTexts: AlertDialogTexts, showCancelBtn) {
    return new Promise((resolve, reject) => {
      swal({
        title: alertDialogTexts.action,
        text: alertDialogTexts.text,
        type: 'warning',
        showCancelButton: showCancelBtn,
        allowOutsideClick: false,
        // buttonsStyling: true,
        // reverseButtons: true,
        confirmButtonText: alertDialogTexts.confirmText,
        cancelButtonText: alertDialogTexts.cancelText
      })
        .then(result => {
          resolve(result);
          // if (result.value) {
          // } else if (result.dismiss === swal.DismissReason.cancel) {
          // 	swal(
          // 			'Cancelled',
          // 			alertDialogTexts.cancelText,
          // 			'error'
          // 	);
          // }
        })
        .catch(error => {
          reject();
        });
    });
  }

  warningDialog(title: string, text: string) {
    swal({
      type: 'warning',
      title: title,
      text: text,
      // backdrop: `radial-gradient(#40404b, #111118) rgba(34,34,40,0.94)`
    });
  }

  customWarningDialog(title: string, text: string, imageUrl = null, imageClass = null) {
    swal({
      imageUrl: imageUrl,
      imageClass: imageClass,
      title: title,
      text: text
    });
  }
}
