
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { ApiService } from './providers/api.service';
import { AuthGuard } from './auth/auth.guard';
import { ScopeGuardService } from './auth/scope-guard.service';
import { SettingsService } from './core/settings/settings.service';
import { AlertDialogService } from './providers/alert-dialog.service';
// https://github.com/ocombe/ng2-translate/issues/218

import { registerLocaleData } from '@angular/common';
import localeUs from '@angular/common/locales/en-US-POSIX';
import { Router } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import { ScriptLoaderService } from './providers/script-loader.service';
import { TenantService } from './core/tenant/tenant.service';

registerLocaleData(localeUs, 'en-US-POSIX');


// export function authHttpServiceFactory(http: Http, options: RequestOptions, router: Router, alertDialog: AlertDialogService) {
//     return new AuthHttp(new AuthConfig({
//         tokenGetter: (async () => {
//             const token = localStorage.getItem('access_token');
//             const jwtHelpr = new JwtHelper();
//             if (!token || jwtHelpr.isTokenExpired(token)) {
//                 alertDialog.warningDialog('End of session', 'your session has timed out please log in again.');
//                 router.navigate(['/login']);
//                 throw new Error('Token Expired');
//             }
//             return token;
//         })
//     }), http, options);
// }

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        HttpClientModule,
        BrowserAnimationsModule, // required for ng2-tag-input
        CoreModule,
        LayoutModule,
        SharedModule.forRoot(),
        RoutesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        AgmCoreModule.forRoot({
            apiKey: environment.googleApiKey
        }),
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'en-US' },
        AlertDialogService,
        SettingsService,
        AuthGuard,
        ScopeGuardService,
        ScriptLoaderService,
        Title,
        // {
        //     provide: AuthHttp,
        //     useFactory: authHttpServiceFactory,
        //     deps: [Http, RequestOptions, Router, AlertDialogService]
        // },
        TenantService,
        ApiService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
