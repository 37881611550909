import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute,  } from '@angular/router';
import { map } from 'rxjs/operators';
import { SocialMediaService } from '../../../modules/social-media/social-media.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
  providers: [SocialMediaService]
})
export class CallbackComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private socialService: SocialMediaService,
  ) { }
  checkTwitterOauthVerifier() {
    // const oauthVerifier: string = this.route.snapshot.queryParams['oauth_verifier'];
    return this.route.queryParamMap
    .pipe(
      map(params => {
        if (params && params['oauth_verifier']) {
          console.log(params);
          return this.socialService.twitterSetSocialAccess(params['oauth_verifier'])
          .pipe(
            map(sa => {
              return sa;
            })
          );
        }
      })
    );
  }
  ngOnInit() {
    console.log("Init");
    this.route.queryParamMap
    .subscribe(params => {
      if (params && params.get('oauth_verifier')) {
        console.log(params);
        this.socialService.twitterSetSocialAccess(params.get('oauth_verifier')).subscribe( sa => {
          console.log(sa);
          this.router.navigate(['city-realtime-alerts']);
        });
      } else {
        console.log("Navigating Home");
        this.router.navigate(['home']);
      }
    });
  }

}
