<div *hasPermission="['read:social-accesses']" class="social-buttons">
  <button *ngIf="!facebookSocialAccess?.hasAccess; else facebookUserButton" class="btn btn-labeled"
    (click)="facebookSignIn()">
    <em class="fa fa-facebook"></em>
    <span>Log in</span>
  </button>
  <ng-template #facebookUserButton>
    <div class="btn-group" dropdown>
      <button class="btn btn-labeled">
        <em class="fa fa-facebook"></em>
        <span>{{ facebookCurrentPage?.name || 'loading...' }}</span>
      </button>
      <button class="btn dropdown-toggle" type="button" dropdownToggle>
        <span class="caret"></span>
        <span class="sr-only">primary</span>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <ng-container *ngIf="facebookPages.length">
          <li *ngFor="let page of facebookPages">
            <a (click)="facebookSelectPage(page)">{{ page?.name }}</a>
          </li>
          <li class="divider"></li>
        </ng-container>
        <li *hasPermission="['delete:social-accesses']"><a (click)="facebookSignOut(facebookSocialAccess)">Sign out</a>
        </li>
      </ul>
    </div>
  </ng-template>
  <div *ngIf="isDemo()">
    <div class="ig-tooltip">
      <mat-icon class="ig-hint">feedback</mat-icon>
      <div class="ig-hint-text">Link your Instagram account to your Facebook page to post.</div>
    </div>
    <button *ngIf="isDemo() && !instagramSocialAccess?.hasAccess; else instagramUserButton" class="btn btn-labeled"
      (click)="facebookSignIn()">
      <em class="fa fa-instagram"></em>
      <span>Log in</span>
    </button>
    <ng-template #instagramUserButton>
      <div class="btn-group" dropdown>
        <button class="btn btn-labeled">
          <em class="fa fa-instagram"></em>
          <span>{{ instagramCurrentPage?.IGUser?.IGUserName || 'loading...' }}</span>
        </button>
        <button class="btn dropdown-toggle" type="button" dropdownToggle>
          <span class="caret"></span>
          <span class="sr-only">pink</span>
        </button>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
          <ng-container *ngIf="instagramPages.length">
            <li *ngFor="let page of instagramPages">
              <a (click)="instagramSelectPage(page)">{{ page?.IGUser?.IGUserName }}</a>
            </li>
            <li class="divider"></li>
          </ng-container>
          <li *hasPermission="['delete:social-accesses']">
            <a (click)="facebookSignOut(instagramSocialAccess)">Sign out</a>
          </li>
        </ul>
      </div>
    </ng-template>
  </div>
  <button *ngIf="!twitterSocialAccess?.hasAccess; else twitterUserButton" (click)="twitterSignIn()"
    class="btn btn-labeled">
    <span class="x-twitter-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg></span>
    <span>Log in</span>
  </button>
  <ng-template #twitterUserButton>
    <div class="btn-group" dropdown>
      <button class="btn btn-labeled">
        <span class="x-twitter-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg></span>
        <span>{{twitterSocialAccess?.username || 'loading...' }}</span>
      </button>
      <button class="btn dropdown-toggle" type="button" dropdownToggle>
        <span class="caret"></span>
        <span class="sr-only">info</span>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li><a (click)="twitterSignOut(twitterSocialAccess)">Sign out</a>
        </li>
      </ul>
    </div>
  </ng-template>
</div>