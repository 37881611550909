import './modernizr.js'; // 'npm run modernizr' to create this file

import '../node_modules/jquery-slimscroll/jquery.slimscroll.js';

import '../node_modules/codemirror/lib/codemirror.js';
import '../node_modules/codemirror/mode/javascript/javascript.js';
import '../node_modules/codemirror/mode/xml/xml.js';
import '../node_modules/codemirror/mode/htmlmixed/htmlmixed.js';
import '../node_modules/codemirror/mode/css/css.js';
import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

let p = platformBrowserDynamic().bootstrapModule(AppModule);
p.then(() => { (<any>window).appBootstrap && (<any>window).appBootstrap(); });
// .catch(err => console.error(err));
