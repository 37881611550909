<div class="social-checkboxes" *ngIf="twitterSocialAccess?.hasAccess || facebookSocialAccess?.hasAccess || instagramSocialAccess?.hasAccess">
  <p>Also, post to:</p>
  <div class="social-btns-group">
    <div class="social-btn-item" *ngIf="facebookSocialAccess?.hasAccess">
      <em class="fa fa-facebook"></em>
      <mat-checkbox [(ngModel)]="socialBoxes.facebook"
        (change)="setFacebookPage()" color="primary" class="text-primary">
      </mat-checkbox>
    </div>
    <div class="social-btn-item" *ngIf="instagramSocialAccess?.hasAccess">
      <em class="fa fa-instagram"></em>
      <mat-checkbox [(ngModel)]="socialBoxes.instagram" (change)="setInstagramPage()" color="primary" class="text-info">
      </mat-checkbox>
    </div>
    <div class="social-btn-item" *ngIf="twitterSocialAccess?.hasAccess && !isScheduled">
      <!-- <em class="fa fa-twitter"></em> -->
      <span class="x-twitter-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg></span>
      <mat-checkbox [(ngModel)]="socialBoxes.twitter" color="primary" class="text-info">
      </mat-checkbox>
    </div>
  </div>
</div>