import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'author'
})
export class AuthorPipe implements PipeTransform {
  transform(value: any, args?: any[]): any {
    if (Array.isArray(args)) {
      const authors = args;
      if (!authors) {
        return { name: '', image: '' };
      }
      const author = authors.find(a => a.id === value);
      if (author) {
        return {
          name: `${author.name}, ${author.title}`,
          image: `${author.imgUrl}`
        };
      }
    }
    return { name: '', image: '' };
  }
}
