import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { environment } from '../../environments/environment';
import { AlertDialogService } from '../providers/alert-dialog.service';
import { BehaviorSubject, timer, Subscription } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

const namespaceAuth0 = 'https://itsimple.io/'; //notice

// 15 min
const idleDuration = 86400000; //  30000 900000;
// 1 min
const tikDuration = 120000; // 15000 120000;

interface IUserProfile {
  id: string;
  sub: string;
  name: string;
  nickname: string;
  picture: string;
  updatedAt: Date;
  permissions: string[];
  roles: string[];
  tenants: string[];
  email: string;
}

class CustomStorage {
  private memoryStorage: { [key: string]: string } = {};

  getItem(key: string): string | null {
    return this.memoryStorage[key] || localStorage.getItem(key);
  }

  setItem(key: string, value: string): void {
    this.memoryStorage[key] = value;
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.warn('LocalStorage not available, using in-memory storage only');
    }
  }

  removeItem(key: string): void {
    delete this.memoryStorage[key];
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.warn('LocalStorage not available, removed from in-memory storage only');
    }
  }
}

@Injectable()
export class AuthService {
  private storage = new CustomStorage();
  private _userProfile: any;
  private _auth0 = new auth0.WebAuth({
    clientID: environment.authConfig.clientID,
    domain: environment.authConfig.domain,
    // responseType: 'token id_token',
    responseType: 'token',
    audience: environment.authConfig.audience,
    // audience: `https://${AUTH_CONFIG.domain}/userinfo`,
    redirectUri: environment.authConfig.callbackURL,
    scope: 'profile openid'
  });
  private hasAuthenticatedSource = new BehaviorSubject<boolean>(false);
  hasAuthenticated$ = this.hasAuthenticatedSource
    .asObservable()
    .pipe(skipWhile(auth => !auth));
  private userProfileSource = new BehaviorSubject<IUserProfile>(undefined);
  userProfile$ = this.userProfileSource
    .asObservable()
    .pipe(skipWhile(profile => !profile));
  private _accessToken: string;
  private _userScopes: string[] = [];
  private _expiresAt: number;

  // private _timerSource = timer(appTokenExpiration, tikDuration);
  private _lastActive = Date.now();
  // private _tikTime = 0;
  // private _timerSub: Subscription;
  private _showingSessionDialog = false;
  // private _titleBefore: string;

  constructor(
    public router: Router,
    private alertDialog: AlertDialogService,
    private titleService: Title
  ) { }

  get accessToken() {
    this._lastActive = Date.now();
    if (this._accessToken) {
      return this._accessToken;
    } else {
      const storedToken = this.storage.getItem('access_token');
      if (storedToken) {
        const [randomPreffix, accessToken] = storedToken.split(':');
        return accessToken;
      } else {
        return undefined;
      }

    }
  }

  get expiresAt() {
    return this._expiresAt
      ? this._expiresAt
      : +this.storage.getItem('expires_at');
  }

  get scopes(): string[] {
    return this._accessToken
      ? this._userScopes
      : JSON.parse(this.storage.getItem('scopes'));
  }
  login(): void {
    this._auth0.authorize();
  }

  handleAuth() {
    console.log("handleAuth");
    if (this.expiresAt > Date.now()) {
      if (this.accessToken) {
        this._getProfile(this.accessToken);
      }
      this.renewToken();
      return;
    }
    this._auth0.parseHash((err, authResult) => {
      console.log("Parse auth results");
      if (authResult && authResult.accessToken) {
        this._setSession(authResult);
        this.router.navigate(['/home']);
      } else if (err) {
        console.log(err);
        this.alertDialog.warningDialog('Unauthorized', err.errorDescription);
        this.router.navigate(['/login']);
      } else {
        console.log("Cannot find access token, navigating to login page")
        this.router.navigate(['/login']);
      }
    });
  }

  private _getProfile(accessToken) {
    console.log("Get Profile");
    this._auth0.client.userInfo(accessToken, (error, profile) => {
      if (profile) {
        console.log('profile:', profile);
        const userProfile: IUserProfile = {
          id: profile.sub,
          name: profile.name,
          nickname: profile.nickname,
          picture: profile.picture,
          sub: profile.sub,
          updatedAt: new Date(profile.updated_at),
          permissions: profile[`${namespaceAuth0}permissions`],
          roles: profile[`${namespaceAuth0}roles`],
          tenants: profile[`${namespaceAuth0}tenants`],
          email: profile[`${namespaceAuth0}email`]
        };
        this._userScopes = userProfile.permissions;
        this.userProfileSource.next(userProfile);
        this.hasAuthenticatedSource.next(true);
        this.storage.setItem('scopes', JSON.stringify(this._userScopes));
      } else if (error) {
        console.log(error);
      }
    });
  }

  private _setSession(authResult): void {
    console.log("Set Session");
    this._accessToken = authResult.accessToken;
    // Set the time that the Access Token will expire at
    this._expiresAt = authResult.expiresIn * 1000 + Date.now();
    const randomPreffix = this.randomString();
    const tokenToStore = `${randomPreffix}:${authResult.accessToken}`; //protect the real accessToken
    this.storage.setItem('access_token', tokenToStore); // TODO: May be vulnerable. change!
    this.storage.setItem('expires_at', `${this._expiresAt}`);
    // check about doing it twice
    if (!this.userProfileSource.getValue()) {
      this._getProfile(this.accessToken);
    }

    // if (!this._timerSub) {
    //   console.log('session timer start');
    //   this.testSessionStart();
    // }
  }

  private _clearSession(): void {
    this.storage.removeItem('access_token');
    this.storage.removeItem('expires_at');
    this.storage.removeItem('role');
    this.storage.removeItem('scopes');
  }

  logout(): void {
    // Remove tokens and expiry time from localStorage
    this._clearSession();
    this._auth0.logout({
      clientID: environment.authConfig.clientID,
      returnTo: environment.baseUrl
    });
    // Go back to the home route
    // this.router.navigate(['/callback']);
  }

  isAuthenticated(): boolean {
    // Check whether the current time is past the
    // Access Token's expiry ti
    const time = Date.now();
    this._lastActive = time;
    return time < this.expiresAt;
  }

  getProfile() {
    return new Promise<any>((resolve, reject) => {
      if (this._userProfile) {
        resolve(this._userProfile);
        return;
      }
      if (!this.accessToken) {
        reject('Access Token must exist to fetch profile');
      }
      const self = this;
      this._auth0.client.userInfo(this.accessToken, (err, profile) => {
        if (profile) {
          self._userProfile = profile;
        }
        // console.log(profile);
        resolve(profile);
      });
    });
  }
  renewToken() {
    // Check for valid Auth0 session // Allowed Web Origins
    console.log("renewToken");
    this._auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken) {
        this._setSession(authResult);
      } else {
        // this._clearSession();
      }
    });
  }

  closeSession() {
    if (
      !this._showingSessionDialog &&
      this._expiresAt &&
      Date.now() > this._expiresAt
    ) {
      this._showingSessionDialog = true;
      this._clearSession();
      this.alertDialog.customWarningDialog('You’ve been logged out', 'For security reason you are now logged out of the portal.', 'assets/img/icons/logout.svg', 'icon-purple');
      setTimeout(() => {
        this._showingSessionDialog = false;
        this.router.navigate(['/login']);
      }, 1000);
    } else if (!this._showingSessionDialog) {
      this.logout()
    }
  }

  // sessionStart() {
  //   this._timerSub = this._timerSource.subscribe(val => {
  //     this._tikTime = Date.now();
  //     // check if it the first tik
  //     if (this._lastActive + appTokenExpiration > this._tikTime) {
  //       if (this._tikTime + appTokenExpiration > this._expiresAt) {
  //         this.renewToken();
  //       }
  //       return;
  //     }
  //     if (
  //       !this._showingSessionDialog &&
  //       this._expiresAt &&
  //       Date.now() < this._expiresAt
  //     ) {
  //       this._titleBefore = this.titleService.getTitle();
  //       this.titleService.setTitle('Still here? 👋');
  //       this._showingSessionDialog = true;
  //       this.alertDialog
  //         .simpleConfirmDialog(
  //           {
  //             action: 'Still here?',
  //             text: `With respect to systems security,
  // 				we are ending this session and you'll need to login again. Want to stay connected?`,
  //             confirmText: 'Yes',
  //             cancelText: 'No'
  //           },
  //           true
  //         )
  //         .then(result => {
  //           if (result['value']) {
  //             this._showingSessionDialog = false;
  //             this._lastActive = Date.now();
  //             this.titleService.setTitle(this._titleBefore);
  //             this.renewToken();
  //           } else if (result['dismiss'] === 'cancel') {
  //             this.logout();
  //           }
  //         });
  //     } else {
  //       this._timerSub.unsubscribe();
  //       this._clearSession();
  //       this.titleService.setTitle('Session Timeout! ⛔️');
  //       this.alertDialog
  //         .simpleConfirmDialog(
  //           {
  //             action: 'Session Timeout!',
  //             text: `Apologies. We tried to check if you still need to stay connected.
  // 					 This session is automatically ended in respect of systems security. Please, login again`,
  //             confirmText: 'Yes',
  //             cancelText: 'No'
  //           },
  //           false
  //         )
  //         .then(result => this.logout());
  //     }
  //   });
  // }

  randomString(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return 'eyJhbGciOiJSU' + result;
  }
}
