import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRoute,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()

export class ScopeGuardService implements CanActivate {

    constructor(public router: Router, private auth: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const { expectedScopes } = route.data;
        let hasAccess = false;
        if (expectedScopes) {
            expectedScopes.map((scope) => {
                if (this.auth.scopes.indexOf(scope) > -1) {
                    hasAccess = true;
                }
            });
        }
        if (hasAccess) {
            return true;
        } else {
            this.router.navigate(['login']);
            return false;
        }
    }
}
