<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
    <!-- START navbar header-->
    <div class="navbar-header">
        <a class="navbar-brand" href="#/">
            <div class="brand-logo">
                <img class="img-responsive" src="assets/img/logo.png" alt="App Logo" />
            </div>
            <div class="brand-logo-collapsed">
                <img class="img-responsive" src="assets/img/logo-single.png" alt="App Logo" />
            </div>
        </a>
    </div>
    <!-- END navbar header-->
    <!-- START Nav wrapper-->
    <div class="nav-wrapper">
        <!-- START Left navbar-->
        <ul class="nav navbar-nav">
            <li>
                <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
                <a class="hidden-xs" trigger-resize="" (click)="toggleCollapsedSideabar()" *ngIf="!isCollapsedText()">
                    <em class="fa fa-navicon"></em>
                </a>
                <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
                <a class="visible-xs sidebar-toggle"
                    (click)="settings.layout.asideToggled =! settings.layout.asideToggled; $event.stopPropagation()">
                    <em class="fa fa-navicon"></em>
                </a>
            </li>
            <li>
                <ng-container *ngIf="tenants$ | async as tenants; else sentence">
                    <ng-container *ngIf="isMultiTenant; else sentence">
                        <div class="tenant-select">
                            <mat-form-field>
                                <mat-label></mat-label>
                                <mat-select placeholder="Select Tenant" [value]="currentTenant"
                                    (selectionChange)=tenantSelected($event.value)>
                                    <mat-option *ngFor="let tenant of tenants" [value]="tenant.name">
                                        {{ tenant.viewName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-template #sentence>
                    <a href="#"><span>Your Civic Engagement Platform</span></a>
                </ng-template>
            </li>
        </ul>
        <!-- END Left navbar-->
        <!-- START Right Navbar-->
        <ul class="nav navbar-nav navbar-right">
            <!-- Fullscreen (only desktops)-->
            <!-- <li class="visible-lg">
                <a #fsbutton (click)="toggleFullScreen($event)">
                    <em class="fa fa-expand"></em>
                </a>
            </li> -->
            <li>
                <a title="Email Us" href="mailto:ask@ITsimple.io">
                    <em class="icon-envelope"></em>
                </a>
            </li>
            <!-- START logout-->
            <li>
                <a title="Logout" (click)="auth.logout()">
                    <em class="icon-logout"></em>
                </a>
            </li>
            <!-- END logout-->
            <!-- <li>
                <a (click)="toggleOffsidebar(); $event.stopPropagation()">
                    <em class="icon-notebook"></em>
                </a>
            </li> -->
        </ul>
        <!-- END Right Navbar-->
    </div>
    <!-- END Nav wrapper-->
</nav>
<!-- END Top Navbar-->