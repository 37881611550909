import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISocialAccess } from '../social-media.service';
// import { isArray } from 'util';

@Component({
  selector: 'app-facebook-pages-dialog',
  templateUrl: './facebook-pages-dialog.component.html',
  styleUrls: ['./facebook-pages-dialog.component.scss']
})
export class FacebookPagesDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<FacebookPagesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISocialAccess) { }

  ngOnInit() {
  }

  pageSelected(page) {
    if (this.data.pages.length > 0) {
      this.data.pages.find(p => p.id === page.id).show = !page.show;
    }
  }

}
