import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AuthService } from '../../../auth/auth.service';

@Directive({
  selector: '[hasPermission]'
})
export class PermissionDirective implements OnInit {
  scopes: any[] = [];
  private permissions = [];
  constructor(
    private auth: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit() {
    this.scopes = this.auth.scopes;
    this.updateView();
  }

  @Input()
  set hasPermission(val) {
    this.permissions = val;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission(): Boolean {
    let hasPermission = false;
    for (const checkPermission of this.permissions) {
      const permissionFound = this.scopes.find(
        x => x.toUpperCase() === checkPermission.toUpperCase()
      );
      if (permissionFound) {
        hasPermission = true;
        break;
      }
    }
    return hasPermission;
  }
}
