import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FacebookPagesDialogComponent } from './facebook-pages-dialog/facebook-pages-dialog.component';
import { SocialButtonsComponent } from './social-buttons/social-buttons.component';
import { SharedModule } from '../../shared/shared.module';
import { SocialMediaService } from './social-media.service';
import { SocialCheckboxesComponent } from './social-checkboxes/social-checkboxes.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
    providers: [SocialMediaService],
    declarations: [SocialButtonsComponent, FacebookPagesDialogComponent, SocialCheckboxesComponent],
    exports: [SocialButtonsComponent, SocialCheckboxesComponent]
})
export class SocialMediaModule { }
