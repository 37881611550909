import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent implements ControlValueAccessor, OnInit {
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline'], // toggled buttons  'strike'
        // ['code-block'],
        [
          {
            color: [
              '#000000',
              '#e60000',
              '#ff9900',
              '#ffff00',
              '#008a00',
              '#0066cc',
              '#9933ff',
              '#ffffff',
              '#cce0f5',
              '#ebd6ff',
              '#bbbbbb',
              '#f06666',
              '#ffc266',
              '#ffff66',
            ],
          },
        ],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        // [{ 'font': [] }],
        // [{ 'align': [] }],

        ['clean'], // remove formatting button

        // ['link'],
        // ['link', 'image', 'video']
        // ['emoji'],
      ],
    },
  };

  @Input() content: string;
  @Output() contentChange = new EventEmitter<string>();
  htmlContent: string;
  constructor(@Self() @Optional() public controlDir: NgControl) {
    // this.controlDir.valueAccessor = this;
  }

  onSelectionChanged(event) {
    if (event.oldRange == null) {
      // onFocus event;
    }
    if (event.range == null) {
      // onBlur event;
    }
  }

  onHTMLContentChanged(event): void {
    if (this.content === this.htmlContent) {
      return;
    }
    this.contentChange.emit(this.filterContent(this.htmlContent));
  }

  onBluer(event): void {
    if (this.content === this.htmlContent) {
      return;
    }

    this.contentChange.emit(this.filterContent(this.htmlContent));
  }

  filterContent(content) {
    const regex = /<a href="http:\/\/voice\.google\.com\/calls\?.*?>/g;
    const str = content.replace(regex, ''); // remove google voice hyperlink
    const str1 = str.replace(/<\/a><\/span><\/a>/g, '<\/span>'); // remove end tags
    const cleanedString = str1.replace(/<\/a><\/u><\/a>/g, '<\/span>'); // remove end tags
    return cleanedString;
  }

  writeValue(obj: any): void { }

  registerOnChange(fn: any): void { }

  registerOnTouched(fn: any): void { }

  ngOnInit() {
    this.htmlContent = this.content ? `${this.content}` : undefined;
    // const control = this.controlDir.control;
    // const validators = control.validator ? [control.validator] : [];
    // control.setValidators(validators);
    // control.updateValueAndValidity();
  }
}
