<h3>Facebook Pages Manage</h3>
<p>
  Hello <b>{{data.username}}</b>,
</p>
<p>Please select the pages that you will like to manage from the portal</p>
<mat-selection-list (selectionChange)="pageSelected($event.option)">
  <mat-list-option [(selected)]="page.show"  [value]='page' *ngFor="let page of data.pages">
    {{ page.name }}
    <!-- <mat-checkbox color="primary" [(ngModel)]="page.show">{{ page.name }}</mat-checkbox> -->
  </mat-list-option>
</mat-selection-list>
<div mat-dialog-actions align="end">
  <div class="mandatory-hint"><span>*</span> Mandatory field</div>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="data" >Confirm</button>
</div>