import { Injectable } from '@angular/core';
import * as _moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
// import * as _moment from 'moment-timezone';

// TODO: Will implement on tenant service
const timezone = 'America/New_York';
_moment.tz.setDefault(timezone);
export const moment = _moment;


const options = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  formatMatcher: 'basic',
  hour12: false,
} as const;
const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);


@Injectable()
export class TimeZoneService {

  constructor() { }

  nowMinutesZero(tenantTimezone?: string) {
    if(tenantTimezone){_moment.tz.setDefault(tenantTimezone);}
    const date = _moment.tz(new Date(), tenantTimezone || timezone).minutes(0).toDate();
    console.log(date);
    return date;
  }

  abbrTenant(date?: Date | string, tenantTimezone?: string) {
    if(tenantTimezone){_moment.tz.setDefault(tenantTimezone);}
    if (!date) {
      return _moment.tz(new Date(), tenantTimezone || timezone).zoneAbbr();
    }
    return _moment.tz(date, tenantTimezone || timezone).zoneAbbr();
  }
}
