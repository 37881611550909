
import {of as observableOf,  Observable } from 'rxjs';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

declare var $: any, ga: Function;

import { SettingsService } from './core/settings/settings.service';
import { AuthService } from './auth/auth.service';
import { TenantService } from './core/tenant/tenant.service';
import { ThemesService } from './core/themes/themes.service';
import { environment } from '../environments/environment';
import { concatMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class.layout-fixed') get isFixed() {
    return this.settings.layout.isFixed;
  }
  @HostBinding('class.aside-collapsed') get isCollapsed() {
    return this.settings.layout.isCollapsed;
  }
  @HostBinding('class.layout-boxed') get isBoxed() {
    return this.settings.layout.isBoxed;
  }
  @HostBinding('class.layout-fs') get useFullLayout() {
    return this.settings.layout.useFullLayout;
  }
  @HostBinding('class.hidden-footer') get hiddenFooter() {
    return this.settings.layout.hiddenFooter;
  }
  @HostBinding('class.layout-h') get horizontal() {
    return this.settings.layout.horizontal;
  }
  @HostBinding('class.aside-float') get isFloat() {
    return this.settings.layout.isFloat;
  }
  @HostBinding('class.offsidebar-open') get offsidebarOpen() {
    return this.settings.layout.offsidebarOpen;
  }
  @HostBinding('class.aside-toggled') get asideToggled() {
    return this.settings.layout.asideToggled;
  }
  @HostBinding('class.aside-collapsed-text') get isCollapsedText() {
    return this.settings.layout.isCollapsedText;
  }

  timedOut = false;
  timer: any;
  private _title: string;
  constructor(
    public settings: SettingsService,
    public themes: ThemesService,
    public auth: AuthService,
    public router: Router,
    public tenant: TenantService,
    private titleService: Title
  ) {}

  async ngOnInit() {
    $(document).on('click', '[href="#"]', e => e.preventDefault());
    if (!environment.production) {
      this._title = this.titleService.getTitle();
      this.titleService.setTitle(`${this._title} | DEV`);
      this.themes.setTheme('G');
    }
    this.auth.handleAuth();
    const auth = await this.auth.hasAuthenticated$
      .pipe(
        concatMap(isAuth => (isAuth ? this.tenant.init() : observableOf({})))
      ).toPromise();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log(
          `google analytics tracking start. Page: ${event.urlAfterRedirects}`
        );
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }

  ngOnDestroy() {
  }

}
