
import {of as observableOf,  Observable } from 'rxjs';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { menu } from './menu';
import { routes } from './routes';
import { PagesModule } from './pages/pages.module';
import { SocialMediaModule } from '../modules/social-media/social-media.module';
import { AuthService } from '../auth/auth.service';
import { flatMap } from 'rxjs/operators';
// import { first } from 'rxjs/operators';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        PagesModule,
        SocialMediaModule,
    ],
    exports: [
        RouterModule
    ]
})

export class RoutesModule {
    constructor(
        public menuService: MenuService,
        tr: TranslatorService,
        auth: AuthService
    ) {
        auth.hasAuthenticated$
            .pipe(
                // first()
                flatMap(isAuth => {
                    console.log('is Auth:', isAuth);
                    if (isAuth) {
                        console.log('Add Menu', isAuth);
                        return menuService.generateMenu(menu);
                    } else { return observableOf([]); }
                })
            ).subscribe();
    }
}
