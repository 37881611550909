  <mat-form-field >
    <input matInput
    placeholder="Date"
    [disabled]="disabled"
    [owlDateTime]="dtRange" 
    [selectMode]="'range'"
    [owlDateTimeTrigger]="dtRange"
    [rangeSeparator]="'to'"
    [ngModel]="value" (ngModelChange)="value=$event"
    (dateTimeChange)="_onChange($event)"
    autocomplete="off"
    required>
    <owl-date-time #dtRange
    [startAt]="startTime || startAt"
    [hour12Timer]="false"
    (afterPickerClosed)="_onClosed()"
    [stepMinute]="5">
    </owl-date-time>
  </mat-form-field>
  <p>start:<span> {{ startTime | date:'MMM d, y, h:mm a':'en-US' }}</span></p>
  <p>end:<span> {{ endTime | date:'MMM d, y, h:mm a':'en-US' }}</span></p>
