
import {of as observableOf,  Observable } from 'rxjs';
import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Self,
  Optional
} from '@angular/core';
import {
  ControlValueAccessor,
  Validator,
  Validators,
  AbstractControl,
  NgControl,
  ValidatorFn
} from '@angular/forms';
import {
  GeocodingService,
  IGeoLocation
} from '../../../providers/geocoding.service';
import {
  map,
  flatMap,
  debounceTime,
  distinctUntilChanged,
  first
} from 'rxjs/operators';
import { TenantService } from '../../../core/tenant/tenant.service';

// export class MyErrorStateMatcher implements ErrorStateMatcher {
//   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//     console.log('Hey Hey');
//     const isSubmitted = form && form.submitted;
//     return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
//   }
// }

function forbiddenAddressValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden = !(
      control.value &&
      control.value.address &&
      control.value.latitude &&
      control.value.longitude
    );
    return forbidden ? { forbiddenAddress: { value: control.value } } : null;
  };
}
@Component({
  selector: 'app-address-location',
  templateUrl: './address-location.component.html',
  styleUrls: ['./address-location.component.scss'],
  providers: [
    // {
    //   provide: NG_VALUE_ACCESSOR,
    //   multi: true,
    //   useExisting: forwardRef(() => AddressLocationComponent),
    // },
    // {
    //   provide: NG_VALIDATORS,
    //   multi: true,
    //   useExisting: forwardRef(() => AddressLocationComponent)
    // },
    GeocodingService
  ]
})
export class AddressLocationComponent
  implements ControlValueAccessor, Validator, OnInit {
  constructor(
    @Self() @Optional() public controlDir: NgControl,
    private geocodingService: GeocodingService,
    private tenantService: TenantService
  ) {
    this.controlDir.valueAccessor = this;
  }

  // Alpharetta
  mapGeoLocation: IGeoLocation = {
    lat: 34.075376,
    lng: -84.29409
  };
  disabled: boolean;
  filteredOptions: Observable<string[]>;
  onMapSelection: boolean;
  @ViewChild('addressInput') addressInput: ElementRef;
  value: { address: string; latitude: number; longitude: number };
  // matcher = new MyErrorStateMatcher();


  @Input() required = true;

  @Input() address: string;
  @Input() longitude: number;
  @Input() latitude: number;
  @Input() showMap = false;
  @Input() mapDblClickable = false;
  @Input() label: string;
  @Input() placeholder: string;
  @Output() addressChange = new EventEmitter<string>();
  @Output() longitudeChange = new EventEmitter<number>();
  @Output() latitudeChange = new EventEmitter<number>();

  hasSelected = false;

  private sessionToken: number;
  private onChange = (_: any) => {};
  private onTouched = () => {};
  registerOnValidatorChange(fn: () => void) {
    console.log('Validator Change');
  }
  validate(ctrl: AbstractControl) {
    return Validators.required(ctrl);
  }
  writeValue(value: any): void {
    // this.addressInput.nativeElement.value = value;
    // this.value = value;
  }
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  _onChangeEvent(value: string) {
    this.addressChange.emit(value);
    this.hasSelected = false;
    // this.updateValues(value, { lat: undefined, lng: undefined });
  }
  _onBluer() {
    if (!this.hasSelected) {
      this.updateValues('', { lat: undefined, lng: undefined });
    }
    this.onTouched();
  }
  onMapDbClick(event) {
    console.log(event);
    const coords: { lat: number; lng: number } = event.coords;
    if (this.onMapSelection) {
      this.geocodingService
        .getAddress(coords.lat, coords.lng)
        .subscribe(res => {
          this.updateValues(res, coords);
        });
    }
  }

  selectedAddress(value: string) {
    this.hasSelected = true;
    this.address = value;
    this.geocodingService.getGeocoding(this.address).subscribe(res => {
      if (res && res.lat && res.lng) {
        this.updateValues(this.address, res);
        this.mapGeoLocation.lat = this.latitude;
        this.mapGeoLocation.lng = this.longitude;
      }
    });
  }
  updateValues(address: string, geo: IGeoLocation) {
    this.latitude = geo.lat;
    this.longitude = geo.lng;
    this.address = address;
    this.addressChange.emit(this.address);
    this.latitudeChange.emit(this.latitude);
    this.longitudeChange.emit(this.longitude);
    this.value = {
      address: address,
      latitude: this.latitude,
      longitude: this.longitude
    };
    this.onChange(this.value);
  }
  ngOnInit() {
    this.sessionToken = Date.now();
    const control = this.controlDir.control;
    this.value = {
      address: this.address,
      latitude: this.latitude,
      longitude: this.longitude
    };
    if (this.latitude && this.longitude) {
      this.mapGeoLocation.lat = this.latitude;
      this.mapGeoLocation.lng = this.longitude;
    } else {
      this.tenantService.currentTenant$
      .pipe(
        first(),
        map(tenant => {
          if (!tenant) {
            return;
          }
          this.mapGeoLocation.lat = tenant.location.latitude;
          this.mapGeoLocation.lng = tenant.location.longitude;
        })).subscribe();
    }
    control.setValue(this.value);
    if (this.value.address) {
      this.hasSelected = true;
    }
    const validators = control.validator
      ? [control.validator, forbiddenAddressValidator()]
      : forbiddenAddressValidator();
    control.setValidators(validators);
    control.updateValueAndValidity();
    // control.statusChanges.subscribe((value) => {
    //   console.log(`state change ${value}`);
    // });
    this.filteredOptions = this.addressChange.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      flatMap(value => {
        const address = value.trim();
        if (address && !this.onMapSelection) {
          return this.geocodingService.autocompleteAddress(
            address,
            this.sessionToken
          );
        } else {
          return observableOf([]);
        }
      })
    );
  }
}
