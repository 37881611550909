<div class="image-button">
  <ng-container *ngIf="!src else Img">
    <div class="image-placeholder" matTooltip="Upload Image" matTooltipPosition="above" aria-label="Image Upload" (click)="openUploadImageDialog()">
      <mat-icon>add_photo_alternate</mat-icon>
    </div>
  </ng-container>
  <ng-template #Img>
    <img class="img-thumbnail image-display" matTooltip="Update Image" matTooltipPosition="above" alt="Image Upload"
    (click)="openUploadImageDialog()"
    [src]="src || placeholderImgUrl" />
    <button class="cancel-button" matTooltip="Delete Image" mat-icon-button (click)="cancelImage()">
      <mat-icon>delete_forever</mat-icon>
    </button>
  </ng-template>
</div>