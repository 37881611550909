<ng-container *ngIf="showMap">
  <mat-checkbox *ngIf="mapDblClickable" color="primary" [(ngModel)]="onMapSelection">Add location. Double click on the map to drop a pin OR enter an address.</mat-checkbox>
  <agm-map (mapDblClick)="onMapDbClick($event)" [latitude]="mapGeoLocation.lat" [longitude]="mapGeoLocation.lng"
    [streetViewControl]="false" [zoom]="17" mapTypeId="roadmap" [disableDoubleClickZoom]=true class="gmap">
    <agm-marker [latitude]=latitude [longitude]=longitude *ngIf="latitude && longitude"
      iconUrl="assets/img/marker/primary.svg"></agm-marker>
  </agm-map>
</ng-container>
<span *ngIf="errMsg" style="color: #3295aa;">{{errMsg}}</span>
<mat-form-field style="padding-top:20px">
  <mat-label *ngIf="onMapSelection" for="address">{{ label || 'Address *' }}</mat-label>
  <mat-label *ngIf="!onMapSelection" for="address">{{ label || 'Address' }}</mat-label>
  <input [(ngModel)]="address" matInput placeholder="{{ placeholder || '' }}" type="text" [value]="address"
    [matAutocomplete]="auto" [disabled]="disabled || onMapSelection" (input)="_onChangeEvent($event.target.value)"
    (blur)="_onBluer()" name="address">
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option"
      (onSelectionChange)="selectedAddress(option)">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<ng-container *ngIf="onMapSelection">
  <p>
    * Shows address closest to the dropped pin.
  </p>
</ng-container>